.header-mob {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 4fr;
  justify-content: space-between;
  /* align-items: center; */
  position: relative;
  padding-top: 2rem;
}
.btn--mob-nav {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: none;
  outline: inherit;
}
.menuicon-open {
  grid-column: 2/3;
  grid-row: 1/2;
  justify-self: right;
}
.mobile-nav {
  display: none;
}
.mobile-nav-list {
  display: none;
  flex-direction: column;
  list-style: none;
  text-decoration: none;
  gap: 1.2rem;
  font-size: 1.5rem;
  position: absolute;
  left: 3rem;
  top: 9rem;
  transition: all 1s;
}

.mobile-nav-link:link,
.mobile-nav-link:visited {
  text-decoration: none;
  color: #5a5a5a;
  font-weight: 600;
  font-size: 1.8rem;
}
