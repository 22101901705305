.header-mob {
  grid-template-rows: 1fr 4fr;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  padding-top: 2rem;
  display: grid;
  position: relative;
}

.btn--mob-nav {
  color: inherit;
  font: inherit;
  cursor: none;
  outline: inherit;
  background: none;
  border: none;
  padding: 0;
}

.menuicon-open {
  grid-area: 1 / 2 / 2 / 3;
  justify-self: right;
}

.mobile-nav {
  display: none;
}

.mobile-nav-list {
  flex-direction: column;
  gap: 1.2rem;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: all 1s;
  display: none;
  position: absolute;
  top: 9rem;
  left: 3rem;
}

.mobile-nav-link:link, .mobile-nav-link:visited {
  color: #5a5a5a;
  font-size: 1.8rem;
  font-weight: 600;
  text-decoration: none;
}

/*# sourceMappingURL=index.e6209081.css.map */
